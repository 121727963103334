import React from 'react';
import './styles.css'

const ShinyButton = ({buttonText}) => {
    
    return (
        <div className="button-container">
            <button>{buttonText}</button>
        </div>
    )
}

export default ShinyButton;