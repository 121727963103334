
function Talks() {
    return (
        <div className="container py-5 text-white text-center">
            <h1 className=" fw-bolder">Vijay Talks About<i className="bi bi-arrow-down text-white"></i></h1>

            <div className="row py-5">

                <div className="col-xs-12 col-md-6">
                    <div className="py-3 d-flex">
                    <i className="bi bi-lightbulb text-warning display-4"></i>
                    <div>
                    <h4 className='fw-bolder py-3 text-start px-4'>Entrepenurship</h4>
                    <p className="lead text-start px-4">I am a serial Tech Entrepreneur since the age of 14 , built mutiple tech ventures , raised funding for them , landed in ET 30 under 30 currently building PESO : A Web 3.0 Finance Solutions App.</p>
                    </div>
                    </div>
                </div>

                <div className=" col-xs-12 col-md-6 ">
                    <div className="py-3 d-flex">
                    <i className="bi bi-boxes text-purple display-4"></i>
                    <div>
                    <h4 className='fw-bolder py-3 text-start px-4'>Web3</h4>
                    <p className="lead text-start px-4">I am a certified Blockchain Developer from IIT Kanpur. My Web 3 App Peso is live on Google Play and Apple Store</p>
                    </div>
                    </div>
                </div>

                <div className=" col-xs-12 col-md-6">
                    <div className="py-3 d-flex">
                    <i className="bi bi-currency-bitcoin text-orange display-4"></i>
                    <div>
                    <h4 className='fw-bolder py-3 text-start px-4'>Crypto</h4>
                    <p className="lead text-start px-4">I create content on Crypto on my Youtube Channel & Instagram</p>
                    </div>
                    </div>
                </div>


                <div className=" col-xs-12 col-md-6">
                    <div className="py-3 d-flex">
                    <i className="bi bi-graph-up-arrow text-pink display-4"></i>
                    <div>
                    <h4 className='fw-bolder py-3 text-start px-4'>Brand Building</h4>
                    <p className="lead text-start px-4">I have an expertise in building brands . My personal brand has more than 50k+ following and Generated total 16 Millions views over various Social Media Platforms. I Built my first startup brand at the age of 19 and raised 2.5 Million INR at VAluation of 60 Million</p>
                    </div>
                    </div>
                </div>
               

              
            </div>
        </div>
    )
}

export default Talks; 