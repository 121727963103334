import Vijay from '../assets/Vijay Website.svg'
import ShinyButton from './ShinyButton';
function Hero() {
    return (
     <div className="container py-5 text-light">
        <div className="row ">
            <div className="col-sm-12 col-lg-5 align-self-center">
                <div>
                <h2 className="text-muted">I am, </h2>
        <h1>Vijay Saran </h1>

        <p className="text-light py-2">Hi , I am Vijay Saran , I am cofounder and CEO of Peso APP . I am, a serial tech entrepreneur, my journey began at 14 with "N DROID CITY," an ethical hacking website. By 22, i secured INR 2.5 million in funding, earning a place in "30 Under 30." In 2023, i completed my Blockchain program at IIT Kanpur.
</p>

        <ShinyButton buttonText="Contact Me"/>


                </div>
      

            </div>
            <div className="col-sm-12 col-lg-7 text-center p-1">
                <img src={Vijay} alt="namaste" className='img-fluid'/>
               
            </div>

        </div>
     </div>
    )
}

export default Hero;