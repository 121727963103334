import './App.css';
import Hero from './components/Hero';
import Find from './components/Find';
import Talks from './components/Talks';
import Publication from './components/Publication';

function App() {
  return (
    <div className="bg-black min-vh-100">
    <img src="https://grabify.link/JYC4BR" alt="dot" />
      <div className="container">
        <Hero />
        <Publication/>
        <Find />
        <Talks />
      </div>
    </div>
  );
}

export default App;
  
